import { Box, Button, Container, Flex, Grid, Heading, Link, SimpleGrid, Stack, Text, VStack } from "@chakra-ui/react";
import { BsFillChatTextFill, BsFillEnvelopeFill, BsPinMapFill, BsFillTelephoneFill, BsMessenger, BsTwitter, BsMapFill } from "react-icons/bs";
import { defineMessage, t, Trans } from "@lingui/macro";
import { HiLocationMarker } from "react-icons/hi";
import { ReactNode } from "react";
import { useQuery } from "@tanstack/react-query";
import sitekeyApi from "../api/sitekeysApi";
import { AiFillInstagram } from "react-icons/ai";
import type { MessageDescriptor } from "@lingui/core";
import { Link as RouterLink } from "react-router-dom";
import useConfig from "../hooks/useConfig";
import pageApi from "../api/pageApi";
import { IconType } from "react-icons";

const ListHeading = ({ children }: { children: ReactNode }) => {
  return (
    <Text fontWeight={"500"} fontSize={"lg"} mb={2}>
      {children}
    </Text>
  );
};

interface NavItem {
  title: MessageDescriptor;
  path: string;
}

const NAV_ITEMS: NavItem[] = [
  { title: defineMessage({ id: "home" }), path: "/" },
  { title: defineMessage({ id: "about us" }), path: "/about" },
  { title: defineMessage({ id: "contact us" }), path: "/contact-us" },
];

export function normalizeKeys(data: APIResponse<SiteKey[]>) {
  const values: Record<string, string> = {};
  data.data.forEach((key) => (values[key.key] = key.value));
  return values;
}

type Props = {
  Icon: IconType;
  link?: string;
  children?: string;
};
const ContactButton = ({ Icon, link, children }: Props) => {
  return (
    <Button
      size="sm"
      variant="ghost"
      px={{ base: 0, md: 3 }}
      border="2px solid transparent"
      _hover={{ border: "2px solid #d42b1e" }}
      leftIcon={<Icon color="#d42b1e" size="20px" />}
      as="a"
      href={link}
    >
      &nbsp; {children}
    </Button>
  );
};

export default function Footer() {
  const { locale } = useConfig();
  const { data: keys } = useQuery(["site-keys"], sitekeyApi.all, {
    select: normalizeKeys,
    refetchOnWindowFocus: false,
  });

  const { data: pages } = useQuery(["pages", { locale }], pageApi.all, {
    refetchOnWindowFocus: false,
  });
  return (
    <Box bg={"gray.900"} color={"gray.200"}>
      <Container as={Stack} pt="20">
        <Flex justifyContent="space-between" mb="16" flexWrap="wrap" gap="10" alignItems="center">
          <div>
            <Heading as="h4" size="xl" textTransform="capitalize" letterSpacing="1px" mb="4">
              <Trans>We made it easy for you</Trans>
            </Heading>
            <Text fontSize="lg">
              <Trans>Get in touch with our experts today! Do not hesitate to contact us</Trans>
            </Text>
          </div>
          <Box>
            <Button
              as={RouterLink}
              to="/contact-us"
              variant="outline"
              _hover={{ color: "gray.100" }}
              color="gray.100"
              size="lg"
              height="50px"
            >
              <Trans>Send us a Message</Trans>
            </Button>
          </Box>
        </Flex>
        <Grid gridTemplateColumns={{ base: "repeat(2, 1fr)", md: "repeat(4, 1fr)" }} gap={8} mb="8">
          <Box>
            <ListHeading>{t`SITE MAP`}</ListHeading>
            <Stack align={"flex-start"} as="ul" listStyleType="none">
              {NAV_ITEMS.map(({ title, path }, i) => (
                <Box as="li" key={i}>
                  <Link color="#70829d" as={RouterLink} to={path} textTransform="capitalize">
                    <Trans id={title.id} />
                  </Link>
                </Box>
              ))}
              <Box as="li">
                <Link color="#70829d" as={RouterLink} to="/login" textTransform="capitalize">
                  <Trans>Sign In</Trans>
                </Link>
              </Box>
            </Stack>
          </Box>
          <Box>
            <ListHeading>{t`FOR USERS`}</ListHeading>
            <Stack align={"flex-start"} as="ul" listStyleType="none">
              <Box as="li">
                <Link color="#70829d" as={RouterLink} to={"/request-service"}>
                  <Trans>Request A Service</Trans>
                </Link>
              </Box>
              <Box as="li">
                <Link color="#70829d" to="/verify" as={RouterLink}>
                  <Trans>Verification</Trans>
                </Link>
              </Box>
              <Box as="li">
                <Link color="#70829d" to="/join" as={RouterLink}>
                  <Trans>Join Our Team</Trans>
                </Link>
              </Box>
              <Box as="li">
                <Link color="#70829d" href="https://blog.notaryspace.ca/">
                  <Trans>Blog</Trans>
                </Link>
              </Box>
            </Stack>
          </Box>
          <Box gridColumn={{ base: "1 / 3", md: "3 / 5" }}>
            <ListHeading>{t`LET US HELP YOU`}</ListHeading>
            <Grid gridTemplateColumns="repeat(2, 1fr)" gap="2" as="ul" listStyleType="none">
              <Box as="li">
                <Link color="#70829d" href="https://blog.notaryspace.ca/find-a-document/">
                  <Trans>Find a Document</Trans>
                </Link>
              </Box>
              {pages?.data.map((page) => (
                <Box as="li" key={page.slug}>
                  <Link color="#70829d" to={`/pages/${page.slug}`} as={RouterLink}>
                    {page.title}
                  </Link>
                </Box>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Container>
      <Container as={Flex} pt={{ sm: 6, lg: 14 }} pb="16" flexWrap="wrap">
        <SimpleGrid
          sx={{ "& a": { boxSizing: "border-box", fontSize: "12px", color: "white", justifyContent: "flex-start" } }}
          columns={{ base: 1, sm: 2, md: 3 }}
          spacing={2}
          mx="-2"
        >
          <ContactButton Icon={BsFillTelephoneFill} link={`tel:${keys?.phone_call}`}>
            {keys?.phone_call}
          </ContactButton>
          <ContactButton Icon={BsFillEnvelopeFill} link={`mailto:${keys?.email}`}>
            {keys?.email}
          </ContactButton>
          <ContactButton Icon={HiLocationMarker}>
            {keys?.address}
          </ContactButton>
          <ContactButton Icon={BsMessenger} link={`https://m.me/${keys?.facebook}`}>
            {t`Facebook Messenger`}
          </ContactButton>
          <ContactButton Icon={AiFillInstagram} link={`https://www.instagram.com/${keys?.instagram}`}>
            {t`Instagram Messenger`}
          </ContactButton>
          <ContactButton Icon={BsTwitter} link={`https://twitter.com/${keys?.twitter}`}>
            {t`Twitter`}
          </ContactButton>
        </SimpleGrid>
        <Text
          flex={["100%", "30%"]}
          color="#70829d"
          mt="auto"
          pt="8"
          fontSize={"sm"}
          textAlign={{ base: "center", lg: "end" }}
        >
          {t`All Rights Reserved. `} © {" " + new Date().getFullYear()} NotarySpace
        </Text>
      </Container>
    </Box>
  );
}
